import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-store-deactivated-warning',
  templateUrl: './store-deactivated-warning.component.html',
  styleUrl: './store-deactivated-warning.component.css'
})
export class StoreDeactivatedWarningComponent {

  constructor(private activeModal: NgbActiveModal) {

  }

  close() {
    this.activeModal.close()
  }
}
