<div class="row d-flex flex-row justify-content-between">
    <div class="col-auto align-self-center">
        <div>
            <select class="form-select form-select-solid form-select-sm" [formControl]="pageCountControl">
                <option value="10" [selected]="selectedPageCount === 10">10</option>
                <option value="25" [selected]="selectedPageCount === 25">25</option>
                <option value="50" [selected]="selectedPageCount === 50">50</option>
                <option value="100" [selected]="selectedPageCount === 100">100</option>
            </select>
        </div>
    </div>
    <mat-paginator class="col-auto align-self-center" [length]="pagination.count"
        [pageSize]="pageCountControl.valueChanges | async" [pageSizeOptions]="[selectedPageCount]" [hidePageSize]="true"
        (page)="onPageEvent($event)" />
</div>