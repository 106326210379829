import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgoText',
})
export class TimeAgoTextPipe implements PipeTransform {

  transform(value: Date | string | number): string {
    const now = new Date().getTime();
    const date = new Date(value).getTime();
    const seconds = Math.floor((now - date) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
      return `TIMEAGO.YEARS`;
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return `TIMEAGO.MONTHS`;
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return `TIMEAGO.DAYS`;
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return `TIMEAGO.HOURS`;
    }
    interval = seconds / 60;
    if (interval > 1) {
      return `TIMEAGO.MINUTES`;
    }
    return `TIMEAGO.SECONDS`;
  
  }

}
