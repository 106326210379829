import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Currency } from '../../../../shared/models/models';
import { Subscription } from 'rxjs';
import { CurrencyService } from '../../../../shared/services/currency.service';
import { getErrorMessage } from '../../../../utilities/validators';
import { DefaultControlValueAccessor } from '../../../../utilities/control-value-accessor';

@Component({
  selector: 'app-currency-dropdown',
  templateUrl: './currency-dropdown.component.html',
  styleUrl: './currency-dropdown.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useClass: DefaultControlValueAccessor,
      multi: true
    }
  ]
})
export class CurrencyDropdownComponent implements OnInit, OnDestroy {
  @Input() formControl!: FormControl<number>

  @Input() remoteErrorMessage?: string

  @Input() fieldName!: string

  @Input() label!: string

  currencies: Currency[] = []


  subscriptions: Subscription[] = []

  constructor(private currenciesService: CurrencyService) {

  }

  ngOnInit(): void {
    this.subscriptions.push(this.currenciesService.getCurrencies().subscribe(value => {
      this.currencies = value
      this.remoteErrorMessage = undefined
    }))
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  errorMessageKey(field: string, formControl: FormControl<number>): string {
    return getErrorMessage(field, formControl)
  }
}
