import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageDropdownComponent } from './components/language-dropdown/language-dropdown.component';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authenticationInterceptor } from './interceptors/authentication.interceptor';
import { languageInterceptor } from './interceptors/language.interceptor';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { loadingInterceptor } from './interceptors/loading.interceptor';
import { responseErrorInterceptor } from './interceptors/response-error.interceptor';
import { ToastComponent } from './components/toast/toast.component';
import { ToastComponentComponent } from './components/toast-component/toast-component.component';
import { ToastFilterPipe } from './pipes/toast-filter.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorAlertComponent } from './components/error-alert/error-alert.component';
import { DefaultPipe } from './pipes/default.pipe';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { timezoneInterceptor } from './interceptors/timezone.interceptor';
import { FloorPipe } from './pipes/floor.pipe';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ErrorMessagePipe } from './pipes/error-message.pipe';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { TablePaginationComponent } from './components/table-pagination/table-pagination.component';
import { NameInitialAvatarComponent } from './components/name-initial-avatar/name-initial-avatar.component';
import { RatingComponent } from './components/rating/rating.component';
import { BadgeComponent } from './components/badge/badge.component';
import { TextComponent } from './components/common-fields/text/text.component';
import { CurrencyDropdownComponent } from './components/common-fields/currency-dropdown/currency-dropdown.component';
import { DisplayLanguageComponent } from './components/common-fields/display-language/display-language.component';
import { StoreContactsComponent } from './components/common-fields/store-contacts/store-contacts.component';
import { StoreSearchComponent } from './components/common-fields/store-search/store-search.component';
import { DeleteWarningComponent } from './components/delete-warning/delete-warning.component';
import { TagsInputComponent } from './components/tags-input/tags-input.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CategoryDropdownComponent } from './components/common-fields/category-dropdown/category-dropdown.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { TextAreaComponent } from './components/common-fields/textarea/textarea.component';
import { TimePipe } from './pipes/time.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { StoreDeactivatedWarningComponent } from './components/store-deactivated-warning/store-deactivated-warning.component';
import { OrderTypeDropdownComponent } from './components/order-type-dropdown/order-type-dropdown.component';
import { DeliveryZonesFieldsComponent } from './components/common-fields/delivery-zones-fields/delivery-zones-fields.component';
import { NotificationDropdownComponent } from './components/notification-dropdown/notification-dropdown.component';
import { TimeAgoNumberPipe } from './pipes/time-ago-number.pipe';
import { TimeAgoTextPipe } from './pipes/time-ago-text.pipe';
import { CountriesDropdownComponent } from './components/common-fields/countries-dropdown/countries-dropdown.component';
import { CitiesDropdownComponent } from './components/common-fields/cities-dropdown/cities-dropdown.component';
import { PhoneNumberComponent } from './components/common-fields/phone-number/phone-number.component';

@NgModule({
  declarations: [
    CountriesDropdownComponent,
    LanguageDropdownComponent,
    LoadingSpinnerComponent,
    TextComponent,
    CurrencyDropdownComponent,
    DisplayLanguageComponent,
    StoreContactsComponent,
    StoreSearchComponent,
    ToastComponent,
    ToastComponentComponent,
    ErrorAlertComponent,
    TimeAgoNumberPipe,
    TimeAgoTextPipe,
    NotificationDropdownComponent,
    DatePickerComponent,
    TextAreaComponent,
    FloorPipe,
    BreadcrumbComponent,
    DateFormatPipe,
    StoreDeactivatedWarningComponent,
    DeleteWarningComponent,
    ToastFilterPipe,
    ShortNumberPipe,
    TimePipe,
    ErrorMessagePipe,
    CategoryDropdownComponent,
    TagsInputComponent,
    NameInitialAvatarComponent,
    RatingComponent,
    CitiesDropdownComponent,
    TablePaginationComponent,
    PhoneNumberComponent,
    BadgeComponent,
    OrderTypeDropdownComponent,
    DeliveryZonesFieldsComponent,
    DefaultPipe,

  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    RouterModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule
  ],
  exports: [
    TranslateModule,
    TextComponent,
    MatPaginatorModule,
    TagsInputComponent,
    CurrencyDropdownComponent,
    TextAreaComponent,
    DateFormatPipe,
    StoreDeactivatedWarningComponent,
    DisplayLanguageComponent,
    DeleteWarningComponent,
    TimePipe,
    CountriesDropdownComponent,
    CitiesDropdownComponent,
    DeliveryZonesFieldsComponent,
    OrderTypeDropdownComponent,
    CategoryDropdownComponent,
    StoreContactsComponent,
    StoreSearchComponent,
    PhoneNumberComponent,
    LanguageDropdownComponent,
    LoadingSpinnerComponent,
    ToastComponent,
    ToastComponentComponent,
    ReactiveFormsModule,
    FormsModule,
    ErrorAlertComponent,
    ShortNumberPipe,
    BreadcrumbComponent,
    DefaultPipe,
    RatingComponent,
    FloorPipe,
    BadgeComponent,
    NameInitialAvatarComponent,
    ErrorMessagePipe,
    DatePickerComponent,
    NgbModule,
    TablePaginationComponent,
    NgSelectModule,
    MatIconModule,
    TagsInputComponent,
    NotificationDropdownComponent
  ],
  providers: [
    provideHttpClient(
      withInterceptors([
        timezoneInterceptor,
        languageInterceptor,
        loadingInterceptor,
        authenticationInterceptor,
        responseErrorInterceptor,
      ])
    ),
    DatePipe
  ],
})
export class SharedModule { }
