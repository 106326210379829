import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastMessage } from '../models/models';
import { ToastSeverity } from '../models/enums';

@Injectable({
  providedIn: 'root',
})
export class ToastService {

  private toast = new BehaviorSubject<ToastMessage | undefined>(undefined)

  toast$ = this.toast.asObservable()

  constructor() { }

  addMessage(toastMessage: ToastMessage) {
   this.toast.next(toastMessage)
  }
}
