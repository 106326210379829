import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GetStoresRequest, GeneralStore,  StoreSearchField, StoreSortBy, StoreStatus } from '../../../models/models'
import { initialPaginatedResult, PaginatedResult } from '../../../../shared/models/models';
import { Subscription } from 'rxjs';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DefaultControlValueAccessor } from '../../../../utilities/control-value-accessor';
import { StoresService } from '../../../services/stores.service';

@Component({
  selector: 'app-store-search',
  templateUrl: './store-search.component.html',
  styleUrl: './store-search.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useClass: DefaultControlValueAccessor,
      multi: true
    }
  ]
})
export class StoreSearchComponent implements OnInit, OnDestroy {

  private readonly STORES_COUNT = 10

  stores: PaginatedResult<GeneralStore>

  @Input() formControl!: FormControl<GeneralStore | null>

  mainStoresSearchTerm: string = ''

  subscriptions: Subscription[] = []

  @Input() remoteErrorMessage?: string

  @Input() isMainStores?: boolean

  @Input() label: string = ''

  constructor(private storesService: StoresService) {
    this.stores = initialPaginatedResult
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  ngOnInit(): void {
    this.subscriptions.push(this.formControl.valueChanges.subscribe(value => {
      this.remoteErrorMessage = undefined
      this.stores = initialPaginatedResult
    }))
  }

  search(term: string) {
    if (term.length < 3) {
      this.stores = initialPaginatedResult
      return
    }

    this.stores.pageIndex = 1

    this.mainStoresSearchTerm = term

    const request: GetStoresRequest = {
      ascending: true,
      pageCount: this.STORES_COUNT,
      pageIndex: this.stores.pageIndex,
      searchField: StoreSearchField.name,
      searchTerm: term,
      storeStatus: StoreStatus.All,
      sortBy: StoreSortBy.createdAt,
      isMainStore: this.isMainStores ? this.isMainStores : undefined
    }

    this.subscriptions.push(this.storesService.getMainStores(request).subscribe((result) => {
      this.stores = result
    }))
  }

  paginateMainStores() {
    if (!this.mainStoresSearchTerm) {
      return
    }

    if (!this.stores.hasNextPage) {
      return
    }

    this.stores.pageIndex++

    const request: GetStoresRequest = {
      ascending: true,
      pageCount: this.STORES_COUNT,
      pageIndex: this.stores.pageIndex,
      searchField: StoreSearchField.name,
      searchTerm: this.mainStoresSearchTerm,
      sortBy: StoreSortBy.createdAt,
      storeStatus: StoreStatus.All
    }

    this.subscriptions.push(this.storesService.getMainStores(request).subscribe((result) => {
      this.stores = result
    }))
  }

  compare(left: GeneralStore, right: GeneralStore) {
    return left.id === right.id
  }
}
