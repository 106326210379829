<div [formGroup]="formGroup">
    <div formArrayName="storeContacts">
        <!--begin::Input group=-->
        <div class="fv-row mb-4 d-flex flex-row justify-content-between align-content-stretch">
            <!--begin::Email-->
            <div class="col-md-11">
                <label class="form-label text-xl fw-bold">{{ 'shared.storeContacts' | translate
                    }}</label>
                    <div>
                        <app-phone-number [dialCode]="initialPrimaryContactDialCode" [formControl]="primaryValueFormControl()" [remoteErrorMessage]="primaryContactRemoteErrorMessage" (countrySelected)="primaryContactDialCodeSelected($event)"/>
                    </div>
                    
                <!-- <input type="text" [formControl]="primaryValueFormControl()" autocomplete="off"
                    class="form-control bg-transparent" /> -->
                <input type="hidden" [formControl]="primaryIdFormControl()" autocomplete="off"
                    class="form-control bg-transparent" />
            </div>

            <div class="d-flex flex-row justify-content-end align-items-center mt-4">
                <!--begin::Indicator label-->
                <img src="assets/media/svg/plus.svg" class="w-50px h-25px hover:cursor-pointer align-self-center mt-8"
                    (click)="addStoreContact()" />
                <!--end::Indicator label-->
            </div>
            <!--end::Email-->
        </div>

        <div class="fv-row mb-4 d-flex flex-row justify-content-between align-content-stretch"
            *ngFor="let contactForm of storeContacts.controls; let i = index" [formGroupName]="i">
            <!--begin::Email-->
            <div class="col-md-11">
                <app-phone-number [formControl]="valueFormControl(i)" (countrySelected)="contactDialCodeSelected($event, i)" [dialCode]="providedDialCodes[i]" />
                <!-- <input type="text" [formControl]="valueFormControl(i)" formControlName="value" autocomplete="off" class="form-control bg-transparent" /> -->
                <input type="hidden" autocomplete="off" [formControl]="idFormControl(i)" formControlName="id" class="form-control bg-transparent" />
                <div class="mt-2" *ngIf="storeContactsRemoteErrorMessages[i]">
                    <app-error-alert [message]="storeContactsRemoteErrorMessages[i]"></app-error-alert>
                </div>
            </div>

            <div class="d-flex flex-row justify-content-end align-items-center">
                <!--begin::Indicator label-->
                <img src="assets/media/svg/minus.svg" class="w-50px h-20px hover:cursor-pointer align-self-center"
                    (click)="removeStoreContact(i)" />
                <!--end::Indicator label-->
            </div>
            <!--end::Email-->
        </div>
        <!--end::Input group=-->
    </div>
</div>