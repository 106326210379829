import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Language, ToastSeverity, UnauthorizedReason } from './enums';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { WorkHour } from '../../models/models';
import { DeliveryZone, StoreLocation } from '../../foodenza/models/models';

export interface ValidationFailureResponse {
  validationMap: { [key: string]: string };
}

export interface Country {
  id: number;
  name: string;
  flag: string;
  code: string;
  dialCode: string;
}

export interface City {
  id: number;
  name: string;
  country: Country
}

export interface NotFoundResponse {
  resourceName: string;
  message: string;
}

export interface UnauthorizedResponse {
  reason: UnauthorizedReason;
}

export interface TooManyRequestsResponse {
  tryAgainAt: string;
}

export interface MessageResponse {
  message: string;
}

export interface ToastMessage {
  message: string;
  severity: ToastSeverity;
}

export interface FieldMap {
  [key: string]: string;
}

export interface Currency {
  id: number;
  code: string;
  name: string;
}

export interface PaginationParameters {
  pageIndex: number;
  pageCount: number;
}

export interface PaginatedResult<T> {
  count: number;
  results: T[];
  pageIndex: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  pagesCount: number;
}

interface FormsErrorMessageMapType {
  [controlName: string]: FieldMap;
}

export const FormsErrorMessageMap: FormsErrorMessageMapType = {
  email: {
    required: 'validation.email.required',
    email: 'validation.email.format',
  },
  emailOrPhone: {
    required: 'validation.emailOrPhone.required',
  },
  fullName: {
    required: 'validation.fullName.required',
    minlength: 'validation.fullName.length',
    maxlength: 'validation.fullName.length',
  },
  password: {
    required: 'validation.password.required',
    minLength: 'validation.password.format',
    pattern: 'validation.password.format',
  },
  address: {
    required: 'validation.address.required',
  },
  currencyForSubscriptionId: {
    required: 'validation.currencyForSubscriptionId.required',
  },
  currencyId: {
    required: 'validation.currencyId.required',
  },
  displayLanguage: {
    required: 'validation.displayLanguage.required',
  },
  name: {
    required: 'validation.name.required',
    maxlength: 'validation.name.maxlength',
    minlength: 'validation.name.minlength',
  },
  confirmPassword: {
    required: 'validation.password.required',
    mustMatch: 'validation.password.mustMatch',
  },
  startAt: {
    required: 'validation.startAt.required',
  },
  endAt: {
    required: 'validation.endAt.required',
  },
  price: {
    required: 'validation.price.required',
  },
  storeGeneralSearch: {
    required: 'validation.storeGeneralSearch.required',
  },
  categoryName: {
    required: 'validation.categoryName.required',
  },
  productTitle: {
    required: 'validation.productTitle.required'
  },
  voucherCode: {
    required: 'validation.voucherCode.required'
  },
  percentage: {
    required: 'validation.percentage.required',
    min: 'validation.percentage.invalid',
    max: 'validation.percentage.invalid'
  },
  maxAmount: {
    required: 'validation.maxAmount.required',
  },
  maxNumberOfUses: {
    required: 'validation.maxNumberOfUses.required',
    min: 'validation.maxNumberOfUses.invalid',
    max: 'validation.maxNumberOfUess.invalid'
  },
  orderStatus: {
    required: 'validation.orderStatus.required'
  },
  categorySortIndex: {
    required: 'validation.categorySortIndex.required'
  }
};

export const initialPaginatedResult = {
  count: 0,
  hasNextPage: false,
  hasPreviousPage: false,
  pageIndex: 1,
  pagesCount: 0,
  results: [],
};

export const storeDisplayLanguages = [
  { name: 'العربية', value: Language.AR },
  { name: 'English', value: Language.EN },
];

export interface PaginationRequest {
  pageIndex: number;
  pageCount: number;
  ascending: boolean;
}

export enum StoreContactType {
  phoneNumber = 'PhoneNumber',
}

export interface StoreContact {
  type: StoreContactType;
  value: string;
}

export interface RegisterAsStoreOwnerForm {
  fullName: FormControl<string>;
  email: FormControl<string>;
  password: FormControl<string>;
  displayLanguage: FormControl<Language>;
  name: FormControl<string>;
  address: FormControl<string | null>;
  whatsAppNumber: FormControl<string | null>;
  mainStore: FormControl<GeneralStore | null>;
  currencyId: FormControl<number>;
  currencyForSubscriptionId: FormControl<number>;
  phoneNumber: FormControl<string | null>;
  primaryContact: FormGroup<{
    id: FormControl<number | null>;
    value: FormControl<string | null>;
  }>;
  storeContacts: FormArray;
  description: FormControl<string | null>;
  cityId: FormControl<number | null>;
  userName: FormControl<string>
}

export interface RegisterAsStoreOwnerRequest {
  email: string;
  fullName: string;
  password: string;
  displayLanguage: string;
  name: string;
  logo: File | null;
  address: string;
  whatsAppNumber: string | null;
  mainStoreId: number | null;
  currencyId: number;
  phoneNumber: string | null;
  currencyForSubscriptionId: number;
  storeContacts: StoreContact[];
  description: string;
  cityId: number | null;
  userName: string;
}

export interface RegisterAsCustomerRequest {
  fullName: string;
  password: string;
  email: string | null;
  phoneNumber: string;
  userName: string;
}

export enum StoreSearchField {
  name = 'Name',
}

export enum StoreSortBy {
  rate = 'Rate',
  createdAt = 'CreatedAt',
  profit = 'Profit',
}

export enum StoreStatus {
  All = 'all',
  Accepted = 'accepted',
  NotAccepted = 'notAccepted',
  Active = 'active',
  Deactivated = 'deactivated',
  Open = 'open',
  Closed = 'closed'
}


export interface GetStoresRequest extends PaginationRequest {
  searchField: StoreSearchField | null;
  sortBy: StoreSortBy | null;
  searchTerm: string | null;
  storeStatus?: StoreStatus;
  isMainStore?: boolean;
}

export interface GeneralStore {
  id: number;
  name: string;
  logo: string;
  slug: string;
  deliveryZones: DeliveryZone[];
  address: string;
}

export interface Store {
  id: number;
  createdAt: string;
  updatedAt: string;
  isAccepted: boolean;
  name: string;
  address: string;
  rate: number;
  isClosed: boolean;
  logo: string;
  earnings: number;
  profit: number;
  earningCurrencyCode: string;
  profitCurrencyCode: string;
  ownerId: string;
  ownerName: string;
  isActive: boolean;
  acceptedAt: string;
}

export interface StoreListForm {
  searchTerm: FormControl<string>;
  statusFilter: FormControl<StoreStatus>;
  sortBy: FormControl<StoreSortBy>;
  pageCount: FormControl<number>;
}

export interface StoreDetails {
  id: number;
  createdAt: Date;
  earnings: number;
  updatedAt: Date;
  isAccepted: boolean;
  acceptedAt: Date;
  whatsAppNumber: string;
  name: string;
  address: string;
  rate: number;
  isClosed: boolean;
  logo: string | File;
  coverPhoto: string | File | null;
  profit: number;
  earningCurrencyCode: string;
  profitCurrencyCode: string;
  isActive: boolean;
  storeContacts: StoreContactDto[];
  mainStore: GeneralStore | null;
  displayLanguage: Language;
  workHours: WorkHour[];
  deliveryZones: DeliveryZone[];
  slug: string;
  cartActive: boolean;
  description: string;
  location: StoreLocation | null
}

export interface StoreContactDto {
  id: number;
  type: string;
  value: string;
}

export interface GeneralStoreForm {
  displayLanguage: FormControl<Language>;
  name: FormControl<string>;
  address: FormControl<string>;
  whatsAppNumber: FormControl<string | null>;
  mainStore: FormControl<GeneralStore | null>;
  storeContacts: FormArray;
  primaryStoreContact: FormGroup<{
    id: FormControl<number>;
    value: FormControl<string | null>;
  }>;
  primaryDeliveryZone: FormGroup<{
    id: FormControl<number | null>;
    zone: FormControl<string | null>;
    fees: FormControl<number | null>;
  }>;
  deliveryZones: FormArray;
  description: FormControl<string | null>,
  cityId: FormControl<number | null>
}

export interface UpdateStoreRequest {
  displayLanguage: Language;
  name: string;
  address: string;
  whatsAppNumber: string | null;
  mainStoreId: number | null;
  storeContacts: StoreContactDto[];
  deliveryZones: DeliveryZone[];
  logo: File | null | string;
  coverPhoto: File | null | string;
  description: string;
  cityId: number | null;
}

export enum StoreSubscriptionStatus {
  All = 'all',
  Expired = 'expired',
  Valid = 'valid',
  Paid = 'paid',
  NotPaid = 'notPaid',
}

export enum StoreSubscriptionSortBy {
  EndAt = 'EndAt',
  StartAt = 'StartAt',
  Price = 'Price',
}

export enum StoreSubscriptionSearchField {
  StoreName,
}

export interface GetStoreSubscriptionsRequest extends PaginationRequest {
  searchTerm: string;
  searchField: StoreSubscriptionSearchField;
  status: StoreSubscriptionStatus;
  sortBy: StoreSubscriptionSortBy;
  startDate: string | null;
  endDate: string | null;
}

export interface StoreSubscriptionDto {
  id: number;
  startAt: string;
  endAt: string;
  price: number;
  isPaid: boolean;
  status: string;
  paidAt: string | null;
  storeName: string;
  storeId: number;
  storeOwnerName: string;
  storeOwnerId: string;
  currencyCode: string;
  logo: string;
}

export interface StoreDetailsSubscriptionsFilterForm {
  status: FormControl<StoreSubscriptionStatus>;
  startDate: FormControl<NgbDateStruct | null>;
  endDate: FormControl<NgbDateStruct | null>;
  pageCount: FormControl<number>;
  pageIndex: FormControl<number>;
}

export interface StoreSubscriptionsFilterForm {
  status: FormControl<StoreSubscriptionStatus>;
  startDate: FormControl<NgbDateStruct | null>;
  endDate: FormControl<NgbDateStruct | null>;
  pageCount: FormControl<number>;
  pageIndex: FormControl<number>;
  searchTerm: FormControl<string>;
}

export interface PaySubscriptionRequest {
  paidAt: string | null;
}

export interface CreateSubscriptionRequest {
  paidAt: string | null;
  startAt: string;
  endAt: string;
  isPaid: boolean;
  price: number;
}

export interface GetStoreReviewsRequest extends PaginationRequest {
  startDate: string | null;
  endDate: string | null;
  isApproved: boolean | null;
}

export interface StoreReviewDto {
  isApproved: boolean;
  id: number;
  review: string;
  rate: number;
  userName: string;
  createdAt: string;
  approvedAt: string;
}

export interface GetStoreReviewsForm {
  pageCount: FormControl<number>;
  startDate: FormControl<NgbDateStruct | null>;
  endDate: FormControl<NgbDateStruct | null>;
  isApproved: FormControl<boolean | null>;
}

export interface Product {
  id: number;
  title: string;
  image: string;
  price: number;
  storeId: number;
  isAvailable: boolean;
  feature: string;
  currencyName: string;
  categoryName: string;
  rate: number;
}

export interface CollectionWrapper<T> {
  results: T[]
}

export interface NotificationsModel{
  id:number,
  createdAt: Date,
  title: string,
  message: string,
  type: string,
  isRead: true,
  readAt:Date,
  payload:any
}
export interface NotificationRequest{
  IsRead:boolean,
  Ascending:boolean,
  PageIndex:number,
  PageCount:number
}

export const DEFAULT_PAGE_COUNT_DASHBOARD = 25

export interface Range {
  min: number,
  max: number
}