export enum Role {
    FoodenzaAdmin = "FoodenzaAdmin",

    SystemAdministrator = "SystemAdministrator",

    Customer = "Customer",

    StoreOwner = "StoreOwner"
}

export enum LoginResponseStatus {
    AccessTokenResponse = "AccessTokenResponse",
    ProceedToTwoFactorAuthResponse = "ProceedToTwoFactorAuthResponse"
}

export enum OtpVerifyReason {
    ForgotPassword = "ForgotPassword",
    EmailConfirm = "EmailConfirm"
}