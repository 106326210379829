import { HttpParams } from "@angular/common/http";

export function getHttpParams<T>(request: T) {
    const paramsMap = request as unknown as { [key: string]: string }

    let params = new HttpParams()

    Object.keys(paramsMap).forEach(key => {
        if (paramsMap[key] === null || paramsMap[key] === undefined) {
            return
        }
        params = params.append(key, paramsMap[key])
    });

    return params
}