import { ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import {
  MatChipInputEvent,
} from '@angular/material/chips';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'app-tags-input',
  templateUrl: './tags-input.component.html',
  styleUrl: './tags-input.component.css',
})
export class TagsInputComponent {

  @Output() itemsChange = new EventEmitter<string[]>();
  @Input() label: string = ''
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER] as const;
  @Input() items: string[] = [];

  announcer = inject(LiveAnnouncer);

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.items.push(value);
      this.itemsChange.emit(this.items);
    }

    event.chipInput!.clear();
  }

  remove(item: any): void {
    const index = this.items.indexOf(item);

    if (index >= 0) {
      this.items.splice(index, 1);
      this.itemsChange.emit(this.items);

      this.announcer.announce(`Removed ${item}`);
    }
  }
}
