import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { Response } from '../models/enums';
import { catchError, switchMap, throwError } from 'rxjs';

export const authenticationInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthenticationService)

  const user = authService.currentUser

  if (user === undefined)
    return next(req)

  const newReq = req.clone({
    setHeaders: {
      'Authorization': `Bearer ${user.accessToken}`
    }
  })

  return next(newReq).pipe(catchError((error: HttpErrorResponse) => {
    if (error.status === Response.Unauthorized) {
      return authService.refreshToken().pipe(switchMap((result) => {
        if (!result) {
          authService.logout()

          return throwError(() => error)
        }

        const newReq = req.clone({
          setHeaders: {
            'Authorization': `Bearer ${authService.currentUser?.accessToken}`
          }
        })

        return next(newReq)
      }))
    }

    return throwError(() => error)
  }))
};
