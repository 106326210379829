<a class="d-flex text-gray-900">
    <!--begin::Avatar-->
    <div class="symbol symbol-circle symbol-25px me-3" *ngIf="name">
        <div class="symbol-label bg-light-secondary">
            <span class="text-capitalize" [ngClass]="initialColorClasses[classIndex]">{{ name.charAt(0).toUpperCase() }}</span>
        </div>
    </div>
    <!--end::Avatar-->
    <!--begin::Name-->
    <span class="fw-bold text-capitalize">{{ name }}</span>
    <!--end::Name-->
</a>