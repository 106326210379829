import { HttpInterceptorFn } from '@angular/common/http';
import { inject, Injector } from '@angular/core';
import { SettingsService } from '../services/settings.service';

export const languageInterceptor: HttpInterceptorFn = (req, next) => {
  const settingsService = inject(Injector).get(SettingsService)

  const language = settingsService.currentLanguage

  const newReq = req.clone({
    setHeaders: {
      'Accept-Language' : language
    }
  })

  return next(newReq)
};
