import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { storeDisplayLanguages } from '../../../../shared/models/models';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Language } from '../../../../shared/models/enums';
import { getErrorMessage } from '../../../../utilities/validators';
import { Subscription } from 'rxjs';
import { DefaultControlValueAccessor } from '../../../../utilities/control-value-accessor';

@Component({
  selector: 'app-display-language',
  templateUrl: './display-language.component.html',
  styleUrl: './display-language.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useClass: DefaultControlValueAccessor,
      multi: true
    }
  ]
})
export class DisplayLanguageComponent implements OnInit, OnDestroy {
  languages = storeDisplayLanguages

  @Input() formControl!: FormControl<Language>

  @Input() remoteErrorMessge?: string

  @Input() withLabel!: boolean

  subscriptions: Subscription[] = []

  ngOnInit(): void {
    this.subscriptions.push(this.formControl.valueChanges.subscribe(value => {
      this.remoteErrorMessge = undefined
    }))
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  errorMessageKey(field: string, formControl: FormControl<Language>): string {
    return getErrorMessage(field, formControl)
  }
}
