<label class="form-label text-xl fw-bold">{{ label }}</label>
<mat-form-field class="example-chip-list">
  <mat-chip-grid #chipGrid aria-label="Enter fruits">
    @for (item of items; track item) {
    <mat-chip-row (removed)="remove(item)" [editable]="true" [aria-description]="'press enter to edit ' + item">
      {{ item }}
      <button matChipRemove [attr.aria-label]="'remove ' + item">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    }
    <input [matChipInputFor]="chipGrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)" />
  </mat-chip-grid>
</mat-form-field>