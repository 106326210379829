import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { City } from '../../../models/models';
import { getErrorMessage } from '../../../../utilities/validators';
import { DefaultControlValueAccessor } from '../../../../utilities/control-value-accessor';

@Component({
  selector: 'app-cities-dropdown',
  templateUrl: './cities-dropdown.component.html',
  styleUrl: './cities-dropdown.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useClass: DefaultControlValueAccessor,
      multi: true
    }
  ]
})
export class CitiesDropdownComponent implements OnInit, OnDestroy {
  @Input() formControl!: FormControl<number | null>

  @Input() remoteErrorMessage?: string

  @Input() cities: City[] = []

  @Input() fieldName!: string

  subscriptions: Subscription[] = []

  constructor() {

  }

  ngOnInit(): void {
    this.subscriptions.push(this.formControl.valueChanges.subscribe(value => {
      if (value) {
        return
      }

      this.cities = []
    }))
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  errorMessageKey(field: string, formControl: FormControl): string {
    return getErrorMessage(field, formControl)
  }
}
