import { Pipe, PipeTransform } from '@angular/core';
import { timeIsoStringToDisplayString } from '../../utilities/date-utilities';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'time',
})
export class TimePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {

  }

  transform(time?: string) {
    if (!time) {
      return ''
    }
    const timeString = timeIsoStringToDisplayString(time)

    return this.datePipe.transform(timeString, "hh:mm a")
  }

}
