import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OrderType } from '../../../orders/models/models';
import { getErrorMessage } from '../../../utilities/validators';
import { DefaultControlValueAccessor } from '../../../utilities/control-value-accessor';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-order-type-dropdown',
  templateUrl: './order-type-dropdown.component.html',
  styleUrl: './order-type-dropdown.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useClass: DefaultControlValueAccessor,
      multi: true
    }
  ]
})
export class OrderTypeDropdownComponent implements OnInit, OnDestroy {
  @Input() withLabel: boolean = false

  @Input() formControl!: FormControl<OrderType>

  @Input() remoteErrorMessage?: string

  subscriptions: Subscription[] = []

  orderTypes = [
    { name: OrderType.TakeAway, value: OrderType.TakeAway },
    { name: OrderType.DineIn, value: OrderType.DineIn },
    { name: OrderType.Delivery, value: OrderType.Delivery }
  ]

  ngOnInit(): void {
    this.subscriptions.push(this.formControl.valueChanges.subscribe(value => {
      this.remoteErrorMessage = undefined
    }))
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  errorMessageKey(fieldName: string, formControl: FormControl<OrderType>): string {
    return getErrorMessage(fieldName, formControl)
  }
}
