import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DefaultControlValueAccessor } from '../../../../utilities/control-value-accessor';
import { Subscription } from 'rxjs';
import { SettingsService } from './../../../services/settings.service';
import { Language } from '../../../models/enums';

@Component({
  selector: 'app-delivery-zones-fields',
  templateUrl: './delivery-zones-fields.component.html',
  styleUrl: './delivery-zones-fields.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useClass: DefaultControlValueAccessor,
      multi: true
    }
  ]
})
export class DeliveryZonesFieldsComponent implements OnInit, OnDestroy {
  @Input() primaryDeliveryZoneFormControl!: FormControl | FormGroup

  @Input() primaryDeliveryZoneRemoteErrorMessage?: string

  @Input() deliveryZones!: FormArray

  @Input() deliveryZonesRemoteErrorMessages: string[] = []

  @Input() formGroup!: FormGroup

  @Output() addClicked = new EventEmitter()

  subscriptions: Subscription[] = []

  dir: 'rtl' | 'ltr' = 'rtl'

  constructor(private SettingsService: SettingsService) {

  }

  addZone() {
    this.addClicked.emit()
  }

  removeZone(index: number) {
    this.deliveryZones.removeAt(index);
  }

  ngOnInit(): void {
    this.subscriptions.push(this.primaryDeliveryZoneFormControl.valueChanges.subscribe(value => {
      this.primaryDeliveryZoneRemoteErrorMessage = undefined
    }))

    this.subscriptions.push(this.SettingsService.language$.subscribe(value => {
      if (value === Language.AR) {
        this.dir = 'rtl'

        return
      }

      this.dir = 'ltr';
    }))
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  zoneFormControl(index: number) {
    return this.deliveryZones.controls.at(index)?.get('zone') as FormControl
  }

  primaryZoneFormControl() {
    return this.primaryDeliveryZoneFormControl.get('zone') as FormControl
  }

  idFormControl(index: number) {
    return this.deliveryZones.controls.at(index)?.get('id') as FormControl
  }

  primaryIdFormControl() {
    return this.primaryDeliveryZoneFormControl.get('id') as FormControl
  }

  feesFormControl(index: number) {
    return this.deliveryZones.controls.at(index)?.get('fees') as FormControl
  }

  primaryFeesFormControl() {
    return this.primaryDeliveryZoneFormControl.get('fees') as FormControl
  }
}
