import { Pipe, PipeTransform } from '@angular/core';
import { ToastMessage } from '../models/models';
import { ToastSeverity } from '../models/enums';

@Pipe({
  name: 'toastFilter',
})
export class ToastFilterPipe implements PipeTransform {

  transform(messages: ToastMessage[], severity: ToastSeverity): ToastMessage[] {
    return messages.filter(message => message.severity === severity)
  }

}
