import { Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';
import { getErrorMessage } from '../../utilities/validators';

@Pipe({
  name: 'errorMessagePipe'
})
export class ErrorMessagePipe implements PipeTransform {

  transform(fieldName: string, formControl: FormControl, ): string {
    return getErrorMessage(fieldName, formControl);
  }

}
