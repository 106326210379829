import { Component, HostListener } from '@angular/core';
import { NotificationService } from '../../../services/Notification.service'
import { PaginatedResult, NotificationsModel, initialPaginatedResult, NotificationRequest } from '../../models/models';
import { NotificationType } from '../../models/enums';
@Component({
  selector: 'app-notification-dropdown',
  templateUrl: './notification-dropdown.component.html',
  styleUrl: './notification-dropdown.component.css'
})
export class NotificationDropdownComponent {
  AllNotification: PaginatedResult<NotificationsModel> = initialPaginatedResult;

  constructor(private _NotificationService: NotificationService) {
    this._NotificationService.getAllNotification();
    this._NotificationService.AllNotification.subscribe((value) => {
      this.AllNotification = value
    })
  }

  async ngOnInit() {

  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    const element = event.target;
    const distanceFromBottom = element.scrollHeight - element.scrollTop - element.clientHeight;

    if (distanceFromBottom <= 0.5) {
      this.loadMoreNotifications();
    }
  }

  @HostListener('click', ['$event.target'])
  onClick(target: HTMLElement) {
    if (target.classList.contains('fa-bell')) {
      this.toggleMenu()
    }
  }

  menuActiveDropDown = false;

  toggleMenu() {
    this.menuActiveDropDown = !this.menuActiveDropDown;
  }

  ReadNotification(idx: number) {
    this._NotificationService.ReadNotification(this.AllNotification.results[idx])

    this.toggleMenu()
  }

  async loadMoreNotifications() {
    if (!this.AllNotification.hasNextPage) {
      return;
    }
    this.AllNotification.pageIndex++;
    const notificationBody: NotificationRequest = {
      Ascending: false,
      PageCount: 10,
      PageIndex: this.AllNotification.pageIndex,
      IsRead: false
    }
    await this._NotificationService.loadMoreNotification(notificationBody)
  }
}
