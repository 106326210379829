import { NgbDateStruct, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { differenceInMinutes, parseISO } from "date-fns";
import moment from 'moment';

export function convertUtcToLocal(utcDate: string) {
    return parseISO(utcDate);
}

export function getMinutesFromNow(utcDate: string) {
    const date = parseISO(utcDate)

    return differenceInMinutes(date, new Date())
}

export function todayToDateStruct(): NgbDateStruct {
    const now = moment()
    return { day: now.date(), month: now.month() + 1, year: now.year() }
}

export function toDateOnlyString(date: NgbDateStruct) {
    const dateMoment = moment({ year: date.year, month: date.month - 1, day: date.day, hour: 12 });
    const isoString = dateMoment.utc().toISOString();
    return isoString;
}

export function parseTimeString(timeString: string): NgbTimeStruct {
    const timeParts = timeString.split(':');
    if (timeParts.length !== 3) {
        throw new Error('Invalid time format');
    }

    const hour = parseInt(timeParts[0], 10);
    const minute = parseInt(timeParts[1], 10);

    // Split seconds and milliseconds if present
    const secondParts = timeParts[2].split('.');
    const second = parseInt(secondParts[0], 10);

    // Construct NgbTimeStruct
    const ngbTime: NgbTimeStruct = {
        hour,
        minute,
        second
    };

    return ngbTime;
}

export function parseTimeStructToIsoDate(time: NgbTimeStruct): string {
    const now = new Date()

    const date = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        time.hour,
        time.minute,
        time.second
    );

    const pad = (n: number) => n.toString().padStart(2, '0');

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hour = pad(date.getHours());
    const minute = pad(date.getMinutes());
    const second = pad(date.getSeconds());
    const millisecond = date.getMilliseconds().toString().padStart(3, '0');

    return `${year}-${month}-${day}T${hour}:${minute}:${second}.${millisecond}`
}

export function timeIsoStringToDisplayString(time: string) {
    return parseTimeStructToIsoDate(parseTimeString(time))
}