import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {
    
  }

  transform(date: Date | string | number) {
    return this.datePipe.transform(date, 'MMM d, y, h:mm a')
  }

}
