import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { getErrorMessage } from '../../../../utilities/validators';
import { DefaultControlValueAccessor } from '../../../../utilities/control-value-accessor';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrl: './textarea.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useClass: DefaultControlValueAccessor,
      multi: true
    }
  ]
})
export class TextAreaComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = []

  @Input() formControl!: FormControl

  @Input() remoteErrorMessage?: string

  @Input() label!: string

  @Input() required!: boolean

  @Input() fieldName!: string

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  ngOnInit(): void {
    this.subscriptions.push(this.formControl.valueChanges.subscribe(value => {
      this.remoteErrorMessage = undefined
    }))
  }

  errorMessageKey(field: string, formControl: FormControl): string {
    return getErrorMessage(field, formControl)
  }
}
