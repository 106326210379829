<!--begin::Breadcrumb-->
<ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0">
    <!--begin::Item-->
    <div class="d-inline-flex mx-1" *ngFor="let navigation of navigationRoutes">
        <li class="breadcrumb-item text-muted">
            <a [routerLink]="navigation.path" class="text-muted text-hover-primary text-lg">{{ navigation.name }}</a>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item">
            <span class="bullet bg-gray-500 w-5px h-2px"></span>
        </li>
    </div>
    <!--end::Item-->
    <!--begin::Item-->
    <li class="breadcrumb-item text-muted text-lg">{{ activeRouteName }}</li>
    <!--end::Item-->
</ul>
<!--end::Breadcrumb-->