import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'floor',
})
export class FloorPipe implements PipeTransform {

  transform(value: number | undefined): number {
    return value ? Math.floor(value) : 0
  }

}
