import { Injectable } from '@angular/core';
import { SendOtpRequest, LoginRequest, LoginResponse, OtpVerifyRequest, TwoFactorAuthResponse, User, ResetPasswordRequest, UserProfile } from '../models/authentication';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map, of, tap, throwError } from 'rxjs';
import { LoginResponseStatus, Role } from '../models/enums';
import { MessageResponse, RegisterAsCustomerRequest, RegisterAsStoreOwnerRequest, TooManyRequestsResponse, ValidationFailureResponse } from '../../shared/models/models';
import { Router } from '@angular/router';
import { toFormData } from '../../utilities/formdata-utilities';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private user?: User

  private readonly USER_STORAGE_KEY = "USER"

  public get currentUser() {
    return this.user
  }

  constructor(private httpClient: HttpClient, private router: Router) {
    const userString = localStorage.getItem(this.USER_STORAGE_KEY)

    if (userString === null) {
      this.user = undefined

      return
    }

    this.user = JSON.parse(userString) as User
  }

  login(request: LoginRequest) {
    return this.httpClient.post(environment.url + 'account/login', request).pipe(catchError(err => {
      if ('validationMap' in err) {
        return of(err as ValidationFailureResponse)
      }

      return throwError(() => err)
    }), map((res => {
      let response: { status: LoginResponseStatus } | ValidationFailureResponse

      if ('validationMap' in res) {
        response = res as ValidationFailureResponse

        return response
      }

      response = res as { status: LoginResponseStatus }

      if (response.status === LoginResponseStatus.AccessTokenResponse) {
        const user = res as User

        const isStoreOwner = user.roles.filter(role => role === Role.StoreOwner).length !== 0

        if (!isStoreOwner) {
          const result: LoginResponse = { loginSuccessful: true, isStoreOwner: isStoreOwner }

          return result
        }

        this.user = user

        this.persistUser()

        const result: LoginResponse = { loginSuccessful: true, isStoreOwner: isStoreOwner }

        return result
      }

      const twoFactorAuthResponse = res as TwoFactorAuthResponse

      const result: LoginResponse = { loginSuccessful: false, proceedToTwoFactorAuthentication: twoFactorAuthResponse, isStoreOwner: false }

      return result
    })))
  }

  refreshToken() {
    if (!this.currentUser) {
      return of()
    }

    return this.httpClient.post<User>(environment.url + 'account/refresh-token', { refreshToken: this.currentUser.refreshToken }).pipe(tap(user => {
      this.user = user

      this.persistUser()
    }), map(flag => {
      return true
    }), catchError(error => {
      return of(false)
    }));
  }

  private persistUser() {
    localStorage.setItem(this.USER_STORAGE_KEY, JSON.stringify(this.user))
  }

  forgotPassword(request: SendOtpRequest) {
    return this.httpClient.post<MessageResponse>(environment.url + 'account/password-reset', request).pipe(catchError(err => {
      if ('validationMap' in err) {
        return of(err as ValidationFailureResponse)
      }

      if ('tryAgainAt' in err) {
        return of(err as TooManyRequestsResponse)
      }

      return throwError(() => err)
    }))
  }

  verifyOtp(request: OtpVerifyRequest) {
    return this.httpClient.post<User>(environment.url + 'account/verify-otp', request).pipe(catchError(err => {
      if ('validationMap' in err) {
        return of(err as ValidationFailureResponse)
      }

      return throwError(() => err)
    }), map((res) => {
      if ('validationMap' in res) {
        return res as ValidationFailureResponse
      }

      const result = res as User

      this.user = result

      this.persistUser()

      return { isStoreOwner: result.roles.filter(role => role === Role.StoreOwner).length !== 0 }
    }))
  }

  resendOtp(request: SendOtpRequest) {
    return this.httpClient.post<MessageResponse>(environment.url + 'account/send-otp', request).pipe(catchError(err => {
      if ('validationMap' in err) {
        return of(err as ValidationFailureResponse)
      }

      if ('tryAgainAt' in err) {
        return of(err as TooManyRequestsResponse)
      }

      return throwError(() => err)
    }))
  }

  resetPassword(request: ResetPasswordRequest) {
    return this.httpClient.post<User>(environment.url + 'account/reset-password', request).pipe(catchError(err => {
      if ('validationMap' in err) {
        return of(err as ValidationFailureResponse)
      }

      return throwError(() => err)
    }), tap(user => {
      if ('accessToken' in user) {
        this.user = user

        this.persistUser()
      }
    }))
  }

  logout() {
    this.user = undefined

    localStorage.removeItem(this.USER_STORAGE_KEY)

    this.router.navigateByUrl('/account/login')
  }

  isStoreOwner() {
    if (!this.user) {
      return false
    }

    return this.user.roles.filter(role => role === Role.StoreOwner).length !== 0
  }

  registerAsStoreOnwer(request: RegisterAsStoreOwnerRequest) {
    const formData = toFormData(request)

    return this.httpClient.post<MessageResponse>(environment.url + 'stores', formData, {
      headers: {
        'enctype': 'multipart/form-data'
      }
    }).pipe(catchError(err => {
      if ('validationMap' in err) {
        const result = err as ValidationFailureResponse

        return of(result)
      }

      return throwError(() => err)
    }))
  }

  registerAsCustomer(request: RegisterAsCustomerRequest) {
    const url = environment.url + 'account'

    return this.httpClient.post(url, request).pipe(catchError(err => {
      if ('validationMap' in err) {
        return of(err as ValidationFailureResponse)
      }

      return throwError(() => err)
    }), map(res => {
      let response: { status: LoginResponseStatus } | ValidationFailureResponse

      if ('validationMap' in res) {
        response = res as ValidationFailureResponse

        return response
      }

      response = res as { status: LoginResponseStatus }

      if (response.status === LoginResponseStatus.AccessTokenResponse) {
        const user = res as User

        const isStoreOwner = user.roles.filter(role => role === Role.StoreOwner).length !== 0

        this.user = user

        this.persistUser()

        const result: LoginResponse = { loginSuccessful: true, isStoreOwner: isStoreOwner }

        return result
      }

      const twoFactorAuthResponse = res as TwoFactorAuthResponse

      const result: LoginResponse = { loginSuccessful: false, proceedToTwoFactorAuthentication: twoFactorAuthResponse, isStoreOwner: false }

      return result
    }))
  }

  getProfile() {
    const url = environment.url + `account`

    return !this.currentUser ? undefined : this.httpClient.get<UserProfile>(url)
  }
}
