import { Component, EventEmitter, input, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Language, ToastSeverity } from '../../models/enums';
import { SettingsService } from '../../services/settings.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-toast-component',
  templateUrl: './toast-component.component.html',
  styleUrl: './toast-component.component.css'
})
export class ToastComponentComponent implements OnInit, OnDestroy {
  @Input() message: string = 'success'

  @Input() severity: ToastSeverity = ToastSeverity.Success

  @Input() id: number = 0

  @Output() toastClose: EventEmitter<unknown> = new EventEmitter()

  showLeft = true

  subscriptions: Subscription[] = []

  @Input() offset: number = 0

  constructor(private settingsService: SettingsService) {

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  ngOnInit(): void {
    this.subscriptions.push(this.settingsService.language$.subscribe((language) => {
      if (language === Language.AR) {
        this.showLeft = true
        
        return
      }

      this.showLeft = false
    }))
  }

  closeButtonClicked() {
    this.toastClose.emit()
  }
}
