<div class="row d-flex flex-row align-items-center">
    <label class="form-label m-0 text-xl fw-bold" [ngClass]="{'required': required}">{{ label }}</label>
    <div class="col-12 col-md-4">
        <ng-select [formControl]="form.controls.country" [clearable]="false" [items]="countries" bindLabel="name" name="country" notFoundText="{{ 'shared.emptyResults' | translate }}" [compareWith]="compare">
            <ng-template ng-label-tmp let-item="item">
                <div class="d-flex flex-row">
                    <img [src]="item.flag" class="w-20px h-25px object-contain" alt="avatar" *ngIf="item.flag">
                    <span class="align-self-center fw-bold mx-2">{{ item.dialCode }}</span>
                </div>
              </ng-template>
            <ng-template ng-option-tmp let-item="item">
                <div class="d-flex flex-row">
                    <img [src]="item.flag" class="w-20px h-25px object-contain" alt="avatar" *ngIf="item.flag">
                    <span class="align-self-center fw-bold mx-2">{{ item.dialCode }}</span>
                </div>
              </ng-template>
        </ng-select>
    </div>

    <div class="col-12 col-md-8 mt-2 mt-md-0">
        
        <input [type]="'text'" [formControl]="formControl" autocomplete="off"
            class="form-control px-2" />
        <div class="mt-2" *ngIf="(formControl.touched && !formControl.valid) || remoteErrorMessage">
            <app-error-alert
                [message]="(errorMessageKey(fieldName, formControl) | translate) | default: remoteErrorMessage!"></app-error-alert>
        </div>
    </div>
</div>