import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { City, CollectionWrapper, Country } from '../models/models';
import { BehaviorSubject, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {
  private countries = new BehaviorSubject<Country[]>([])

  private cities : { cities: City[], countryId: number }[] = []

  $countries = this.countries.asObservable()

  constructor(private httpClient: HttpClient) {
    this.getCountries()
  }

  getCountries() {
    if (this.countries.value.length !== 0) {
      return
    }

    const url = environment.url + `countries`

    this.httpClient.get<CollectionWrapper<Country>>(url).subscribe(countries => {
      this.countries.next(countries.results)
    })
  }

  getCountryCities(countryId: number) {
    const exists = this.cities.filter(city => city.countryId === countryId).length !== 0

    if (exists) {
      return of(this.cities.filter(city => city.countryId === countryId)[0].cities)
    }

    const url = environment.url + `countries/${countryId}`

    return this.httpClient.get<CollectionWrapper<City>>(url).pipe(map(cities => {
      this.cities.push({countryId: countryId, cities: cities.results})

      return cities.results
    }))
  }
}
