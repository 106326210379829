<!--begin::Rating-->
<div class="rating">
    <!--begin::Overall rating-->
    <div class="rating-label checked" *ngFor="let _ of [].constructor(rate | floor)">
        <i class="ki-outline ki-star fs-6"></i>
    </div>
    <div class="rating-label" *ngFor="let _ of [].constructor(5 - (rate | floor))">
        <i class="ki-outline ki-star fs-6"></i>
    </div>
    <!--end::Overall rating-->
</div>
<!--end::Rating-->