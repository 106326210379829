import { AbstractControl, FormControl } from '@angular/forms';
import { FormsErrorMessageMap } from '../shared/models/models';

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
    return (group: AbstractControl) => {
        const control = group.get(controlName);
        const matchingControl = group.get(matchingControlName);

        if (!control || !matchingControl) {
            return null;
        }

        // return if another validator has already found an error on the matchingControl
        if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
            return null;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
        return null;
    }
}

export function getErrorMessage(field: string, formControl: FormControl) {
    if (formControl && formControl.errors) {
        const errorKeys = Object.keys(formControl.errors);
        const fieldErrorMap = FormsErrorMessageMap[field];

        if (fieldErrorMap) {
            for (const errorKey of errorKeys) {
                if (fieldErrorMap[errorKey]) {
                    return fieldErrorMap[errorKey];
                }
            }
        }
    }

    return '';
}