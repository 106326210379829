<div class="modal-header">
    <h4 class="modal-title">{{ 'shared.warning' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p class="fw-bold">{{'shared.storeIsDeactivated' | translate }}</p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="close()">{{'shared.close' | translate }}</button>
</div>