<div class="wrapper">
	<div class="notification_wrap">
		<div class="notification_icon">
			<i class="fas fa-bell"></i>
      <span *ngIf="AllNotification.count!=0">{{AllNotification.count}}</span>
		</div>
		
	</div>
  <div class="dropdown" [ngClass]="{'activeNotificationDropDown':menuActiveDropDown}" (scroll)="onScroll($event)">
    <div  class="notify_item" *ngFor="let item of AllNotification.results;let i = index" (click)="ReadNotification(i)">
      
      <div class="notify_info" >
        <p>  <span>{{item.title}}</span> <br> {{item.message}}</p>
        <span class="notify_time">{{ item.createdAt | timeAgoNumber}} {{ item.createdAt | timeAgoText |translate }}</span>
      </div>
    </div>
    <div *ngIf="AllNotification.count===0" class="EmptyNotification">
      <p>{{"Notification.EmptyNotification"|translate}}</p>
    </div>
  </div>
</div>