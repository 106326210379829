import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoadingStateService } from '../../services/loading-state.service';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrl: './loading-spinner.component.css'
})
export class LoadingSpinnerComponent implements OnInit {

  show = false

  constructor(private stateService: LoadingStateService, private cdr: ChangeDetectorRef) {
    
  }

  ngOnInit(): void {
    this.stateService.loading$.subscribe((loading) => {
      this.show = loading

      this.cdr.detectChanges()
    })  
  }

}
