import { Component, input, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrl: './date-picker.component.css'
})
export class DatePickerComponent implements OnInit, OnDestroy {
  @Input() label: string = ''

  @Input() dateControl!: FormControl<NgbDateStruct | null>

  @Input() remoteErrorMessage?: string

  @Input() fieldName!: string

  @Input() required: boolean = false

  subscriptions: Subscription[] = []

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  ngOnInit(): void {
    this.subscriptions.push(this.dateControl.valueChanges.subscribe(value => {
      this.remoteErrorMessage = undefined
    }))
  }
}
