import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DefaultControlValueAccessor } from '../../../../utilities/control-value-accessor';
import { Country, StoreContactType } from '../../../models/models';

@Component({
  selector: 'app-store-contacts',
  templateUrl: './store-contacts.component.html',
  styleUrl: './store-contacts.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useClass: DefaultControlValueAccessor,
      multi: true
    }
  ]
})
export class StoreContactsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() primaryContactFormControl!: FormControl | FormGroup

  @Input() primaryContactRemoteErrorMessage?: string

  @Input() storeContacts!: FormArray

  @Input() storeContactsRemoteErrorMessages: string[] = []

  @Input() formGroup!: FormGroup

  subscriptions: Subscription[] = []

  primaryContactDialCode: string | null = null

  initialPrimaryContactDialCode: string | null = null

  @Input() primaryContact: { id: number | null, value: string | null } = {
    id: null,
    value: null
  }

  @Input() contacts: { id: number | null, value: string | null }[] = []

  contactsDialCodes: (string | null)[] = []

  providedDialCodes: (string|null)[] = []

  constructor(private formBuilder: FormBuilder) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    const primaryValueControl = this.primaryValueFormControl()

    const primaryIdControl = this.primaryIdFormControl()

    if (this.primaryContact.id && this.primaryContact.value) {
      const valueSplit = this.primaryContact.value.split(' ')

      primaryIdControl.setValue(this.primaryContact.id)

      if (valueSplit.length !== 2) {
        primaryValueControl.setValue(this.primaryContact.value)
      }

      primaryValueControl.setValue(valueSplit[1])

      this.initialPrimaryContactDialCode = valueSplit[0]
      this.primaryContactDialCode = valueSplit[0]
    }

    this.addContacts()
  }

  addStoreContact() {
    const formGroup = this.formBuilder.group({
      id: new FormControl(null, { nonNullable: false }),
      value: new FormControl('', { nonNullable: false }),
      type: new FormControl(StoreContactType.phoneNumber, { nonNullable: true })
    });

    this.contactsDialCodes.push(null)

    this.contactsDialCodes = [... this.contactsDialCodes]

    this.storeContacts.push(formGroup);

    this.contacts.push({
      id: null,
      value: null
    })

    this.subscriptions.push(formGroup.controls.value.valueChanges.subscribe(value => {
      const index = this.storeContacts.controls.length - 1

      this.contacts[index] = {
        id: this.idFormControl(index).value,
        value: this.contactsDialCodes[index] + ' ' + value
      }

      if (index > this.storeContactsRemoteErrorMessages.length - 1) {
        return
      }

      this.storeContactsRemoteErrorMessages = this.storeContactsRemoteErrorMessages.filter((value, i) => index !== i)
    }))
  }

  addContacts() {
    this.storeContacts.clear()
    
    if (this.contacts.length === 0) {
      return
    }

    const dialCodes: (string | null)[] = []

    this.contacts.forEach(contact => {
      const valueSplit = contact.value?.split(' ')

      if (!valueSplit) {
        return
      }

      const formGroup = this.formBuilder.group({
        id: new FormControl(contact.id, { nonNullable: false }),
        value: new FormControl(valueSplit[1], { nonNullable: false }),
        type: new FormControl(StoreContactType.phoneNumber, { nonNullable: true })
      })

      this.storeContacts.push(formGroup)

      dialCodes.push(valueSplit[0])

      this.subscriptions.push(formGroup.controls.value.valueChanges.subscribe(value => {
        const index = this.storeContacts.controls.length - 1

        this.contacts[index] = {
          id: this.idFormControl(index).value,
          value: this.contactsDialCodes[index] + ' ' + value
        }

        if (index > this.storeContactsRemoteErrorMessages.length - 1) {
          return
        }

        this.storeContactsRemoteErrorMessages = this.storeContactsRemoteErrorMessages.filter((value, i) => index !== i)
      }))
    })

    this.providedDialCodes = dialCodes
    this.contactsDialCodes = dialCodes
  }

  removeStoreContact(index: number) {
    this.storeContacts.removeAt(index)

    this.contacts.splice(index, 1)

    this.contactsDialCodes = this.contactsDialCodes.filter((value, valueIndex) => {
      if (index === valueIndex) {
        return false
      }

      return true
    })

    this.providedDialCodes = this.providedDialCodes.filter((value, valueIndex) => {
      if (index === valueIndex) {
        return false
      }

      return true
    })
  }

  ngOnInit(): void {
    const primaryValueControl = this.primaryValueFormControl()

    const primaryIdControl = this.primaryIdFormControl()

    this.subscriptions.push(primaryValueControl.valueChanges.subscribe(value => {
      this.primaryContactRemoteErrorMessage = undefined

      this.primaryContact.id = primaryIdControl.value

      this.primaryContact.value = this.primaryContactDialCode + ' ' + value
    }))
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  valueFormControl(index: number) {
    return this.storeContacts.controls.at(index)?.get('value') as FormControl
  }

  primaryValueFormControl() {
    return this.primaryContactFormControl.get('value') as FormControl
  }

  idFormControl(index: number) {
    return this.storeContacts.controls.at(index)?.get('id') as FormControl
  }

  primaryIdFormControl() {
    return this.primaryContactFormControl.get('id') as FormControl
  }

  primaryContactDialCodeSelected(country: Country | null) {
    this.primaryContactDialCode = country?.dialCode ?? null
  }

  contactDialCodeSelected(country: Country | null, index: number) {
    if (this.contactsDialCodes[index] === undefined) {
      return
    }

    this.contactsDialCodes = this.contactsDialCodes.map((value, valueIndex) => {
      if (index === valueIndex) {
        return country?.dialCode ?? null
      }

      return value
    })
  }
}
