import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-name-initial-avatar',
  templateUrl: './name-initial-avatar.component.html',
  styleUrl: './name-initial-avatar.component.css'
})
export class NameInitialAvatarComponent {
  @Input() name: string = ''

  initialColorClasses: string[]

  classIndex: number

  constructor() {
    this.initialColorClasses = [
      'text-primary',
      'text-success',
      'text-info',
      'text-danger'
    ]

    this.classIndex = Math.floor(Math.random() * this.initialColorClasses.length)
  }
}
