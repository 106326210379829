import { Component, Input } from '@angular/core';
import { BadgeSeverity } from '../../models/enums';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.css'
})
export class BadgeComponent {
  @Input() severity: BadgeSeverity = BadgeSeverity.Primary

  @Input() text: string = ''

  badgeSeverity = BadgeSeverity
}
