import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../services/settings.service';
import { Language } from '../../models/enums';

@Component({
  selector: 'app-language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrl: './language-dropdown.component.css'
})
export class LanguageDropdownComponent implements OnInit {
  menuOpen: boolean = false

  selectedLanguage = { name: 'العربية', flag: 'assets/media/flags/egypt.svg' }

  constructor(private settingsService: SettingsService) {

  }

  ngOnInit(): void {
    this.settingsService.language$.subscribe((language) => {
      if (language === Language.AR) {
        this.selectedLanguage = { name: 'العربية', flag: 'assets/media/flags/egypt.svg' }

        return
      }

      this.selectedLanguage = { name: 'English', flag: 'assets/media/flags/united-states.svg' }
    })
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen
  }

  arabic() {
    this.settingsService.updateLanguage(Language.AR, true)

    location.reload()
  }

  english() {
    this.settingsService.updateLanguage(Language.EN, true)

    location.reload()
  }
}
