import { HttpInterceptorFn } from '@angular/common/http';
import { catchError, of, throwError } from 'rxjs';
import { Response, ToastSeverity, UnauthorizedReason } from '../models/enums';
import { MessageResponse, NotFoundResponse, TooManyRequestsResponse, UnauthorizedResponse } from '../models/models';
import { inject } from '@angular/core';
import { ToastService } from '../services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreDeactivatedWarningComponent } from '../components/store-deactivated-warning/store-deactivated-warning.component';

export const responseErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const toastService = inject(ToastService)
  const translateService = inject(TranslateService)
  const modalService = inject(NgbModal)

  return next(req).pipe(catchError(err => {
    if (err.status === Response.Forbidden) {
      const response = err.error as UnauthorizedResponse

      if (response.reason === UnauthorizedReason.StoreDeactivatedOrNotAccepted) {
        modalService.open(StoreDeactivatedWarningComponent)
      
        return of()
      }


      translateService.get('shared.unauthorized').subscribe((message) => {
        toastService.addMessage({ message: message, severity: ToastSeverity.Warning })
      })

      return of()
    }

    if (err.status === Response.Unauthorized) {
      return throwError(() => err)
    }

    if (err.status === Response.NotFound) {
      const response = err.error as NotFoundResponse

      toastService.addMessage({ message: response.message, severity: ToastSeverity.Danger })

      return throwError(() => response)
    }

    if (err.status == Response.TooManyRequests) {
      const response = err.error as TooManyRequestsResponse

      translateService.get('authentication.tooManyRequests').subscribe((message) => {
        toastService.addMessage({ message: message, severity: ToastSeverity.Danger })
      })

      return throwError(() => response)
    }

    const response = err.error as MessageResponse

    if (!response) {
      translateService.get('shared.requestFailure').subscribe(message => {
        toastService.addMessage({ message: message, severity: ToastSeverity.Warning })
      })
    }

    // toastService.addMessage({ message: response.message, severity: ToastSeverity.Warning })

    return throwError(() => response)
  }))
};
