import { Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-warning',
  templateUrl: './delete-warning.component.html',
  styleUrl: './delete-warning.component.css'
})
export class DeleteWarningComponent {
  @Output() confirmed = new EventEmitter

  constructor(private activeModal: NgbActiveModal) {

  }

  confirm() {
    this.confirmed.emit()
  }

  close() {
    this.activeModal.close()
  }
}
