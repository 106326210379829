<div class="row">
<label class="form-label text-xl fw-bold" [ngClass]="{'required': required}"> {{ label }} </label>
<div class="input-group">
    <input class="form-control" name="dp" [formControl]="dateControl" ngbDatepicker #date="ngbDatepicker" />
    <button class="btn btn-outline-secondary bi bi-calendar3" (click)="date.toggle()" type="button"></button>
</div>
<div class="my-2" *ngIf="remoteErrorMessage || (dateControl.dirty && !dateControl.valid)">
    <app-error-alert
        [message]="((fieldName | errorMessagePipe: dateControl) | translate) | default: remoteErrorMessage!" />
</div>
</div>