import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { ToastMessage } from '../../models/models';
import { ToastSeverity } from '../../models/enums';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.css',
})
export class ToastComponent implements OnInit, OnDestroy {

  messages: ToastMessage[] = []

  severity: ToastSeverity = ToastSeverity.Success

  messagesSubscription: Subscription = new Subscription()

  timeouts: number[] = []

  private offSet: number = 70

  constructor(private toastService: ToastService) {

  }

  ngOnDestroy(): void {
    this.messagesSubscription.unsubscribe()

    this.timeouts.forEach(timeout => window.clearTimeout(timeout))
  }

  ngOnInit(): void {
    this.messagesSubscription = this.toastService.toast$.subscribe((toast) => {
      if (toast === undefined) {
        return
      }
      this.messages = [...this.messages, toast];
      this.severity = toast.severity

      const index = this.messages.length - 1

      this.timeouts.push(window.setTimeout(() => {
        this.removeToast(index)
      }, 5000))
    })
  }

  calculateOffset(index: number) {
    return index * this.offSet
  }

  removeToast(index: number) {
    this.messages = this.messages.filter((value, i) => index !== i)
  }
}
