import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Language, Theme } from '../models/enums';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import localeEn from '@angular/common/locales/en';
import localeAr from '@angular/common/locales/ar-EG';
import { registerLocaleData } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private theme = new BehaviorSubject<string>(Theme.LIGHT)

  theme$ = this.theme.asObservable()

  private language = new BehaviorSubject<string>(Language.AR)

  language$ = this.language.asObservable()

  public get currentLanguage() {
    return this.language.value
  }

  private setLocaleData() {
    if (this.language.value === Language.AR) {
      registerLocaleData(localeAr)

      return
    }

    registerLocaleData(localeEn)
  }

  public get currentLocale() {
    return this.language.value === Language.AR ? 'ar-EG' : 'en-US'
  }

  public get currentTheme() {
    return this.theme.value
  }

  private readonly SETTING_STORAGE_KEY = "SETTINGS"

  constructor(@Inject(DOCUMENT) private document: Document, private translateService: TranslateService) {
    const storedSettings = localStorage.getItem(this.SETTING_STORAGE_KEY)

    let settings = storedSettings === null ? null : JSON.parse(storedSettings) as { theme: Theme, language: Language }

    if (settings === null) {
      settings = { theme: Theme.LIGHT, language: Language.AR }

      localStorage.setItem(this.SETTING_STORAGE_KEY, JSON.stringify(settings))
    }

    this.updateLanguage(settings.language, true)

    this.document.documentElement.setAttribute('lang', settings.language.toLowerCase())

    this.translateService.use(settings.language)

    this.updateTheme(settings.theme)

    this.document.documentElement.setAttribute('data-bs-theme', this.theme.value)

    this.setBodyDirAttribute()
  }

  updateTheme(theme: Theme): void {
    this.theme.next(theme)

    this.document.documentElement.setAttribute('data-bs-theme', this.theme.value)

    const settings = { theme: this.theme.value, language: this.language.value }

    localStorage.setItem(this.SETTING_STORAGE_KEY, JSON.stringify(settings))
  }

  updateLanguage(language: Language, persist: boolean): void {
    this.language.next(language)

    this.setBodyDirAttribute()

    this.document.documentElement.setAttribute('lang', language.toLowerCase())

    this.translateService.use(language)

    const settings = { theme: this.theme.value, language: this.language.value }

    this.setLocaleData()

    if (!persist) {
      return
    }

    localStorage.setItem(this.SETTING_STORAGE_KEY, JSON.stringify(settings))
  }

  private setBodyDirAttribute() {
    if (this.language.value === Language.AR) {
      this.document.body.setAttribute('dir', 'rtl')

      return
    }

    this.document.body.setAttribute('dir', 'ltr')
  }
}
