import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CreateSubscriptionRequest, GetStoreReviewsRequest, GetStoresRequest, GetStoreSubscriptionsRequest, GeneralStore, PaySubscriptionRequest, Store, StoreDetails, StoreReviewDto, StoreSubscriptionDto, UpdateStoreRequest, RegisterAsStoreOwnerRequest, CollectionWrapper } from '../models/models';
import { catchError, of, throwError } from 'rxjs';
import { MessageResponse, PaginatedResult, ValidationFailureResponse } from '../../shared/models/models';
import { getHttpParams } from '../../utilities/params-utilities';
import { toFormData } from '../../utilities/formdata-utilities';
import { CollectionViewer } from '@angular/cdk/collections';
import { WorkHour } from '../../models/models';
import { DayOfWeek, ToastSeverity } from '../models/enums';
import { SEVEN } from '@angular/cdk/keycodes';

@Injectable({
  providedIn: 'root'
})
export class StoresService {

  constructor(private httpClient: HttpClient) {

  }

  toggleCartActive() {
    const url = environment.url + `stores/cart-status`

    return this.httpClient.patch<MessageResponse>(url, null)
  }

  getMainStores(request: GetStoresRequest) {
    const params = getHttpParams(request)

    return this.httpClient.get<PaginatedResult<GeneralStore>>(environment.url + 'application/stores-general', { params: params })
  }

  getStoreDetails() {
    const url = environment.url + `stores`

    return this.httpClient.get<StoreDetails>(url)
  }

  toggleOpen() {
    const url = environment.url + `stores/status`

    return this.httpClient.patch<MessageResponse>(url, null)
  }

  updateStore(request: UpdateStoreRequest) {
    const url = environment.url + `stores`

    const formData = toFormData(request)

    return this.httpClient.put<MessageResponse>(url, formData, {
      headers: {
        'enctype': 'multipart/form-data'
      }
    }).pipe(catchError(err => {
      if ('validationMap' in err) {
        return of(err as ValidationFailureResponse)
      }

      return throwError(() => err)
    }))
  }

  getStoreSubscriptions(request: GetStoreSubscriptionsRequest) {
    const url = environment.url + 'stores/subscriptions'

    const params = getHttpParams(request)

    return this.httpClient.get<PaginatedResult<StoreSubscriptionDto>>(url, { params: params })
  }

  getStoreReviews(request: GetStoreReviewsRequest) {
    const url = environment.url + `stores/reviews`

    const params = getHttpParams(request)

    return this.httpClient.get<PaginatedResult<StoreReviewDto>>(url, { params: params })
  }

  approveReview(reviewId: number) {
    const url = environment.url + `stores/reviews/${reviewId}`

    return this.httpClient.patch<MessageResponse>(url, null)
  }

  deleteReview(reviewId: number) {
    const url = environment.url + `stores/reviews/${reviewId}`

    return this.httpClient.delete<MessageResponse>(url)
  }

  getWorkHours() {
    const url = environment.url + `stores/work-hours-for-owner`

    return this.httpClient.get<CollectionWrapper<WorkHour>>(url)
  }

  manageWorkHours(request: { workHours: { dayOfWeek: DayOfWeek, openAt: string, closeAt: string }[] }) {
    const url = environment.url + `stores/work-hours`

    return this.httpClient.post<MessageResponse>(url, request).pipe(catchError(err => {
      if ('validationMap' in err) {
        return of(err as ValidationFailureResponse)
      }

      return throwError(() => err)
    }))
  }
}
