import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgoNumber',
})
export class TimeAgoNumberPipe implements PipeTransform {

  transform(value: Date | string | number): string {
    const now = new Date().getTime();
    const date = new Date(value).getTime();
    const seconds = Math.floor((now - date) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
      return `${Math.floor(interval)}`;
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return `${Math.floor(interval)}`;
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return `${Math.floor(interval)}`;
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return `${Math.floor(interval)}`;
    }
    interval = seconds / 60;
    if (interval > 1) {
      return `${Math.floor(interval)}`;
    }
    return `${Math.floor(seconds)}`;
  
  }

}
