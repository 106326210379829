import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { initialPaginatedResult, PaginatedResult } from '../../../models/models';
import { Category } from '../../../../categories/models/models';
import { FormControl } from '@angular/forms';
import { CategoriesService } from '../../../../categories/services/categories.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-category-dropdown',
  templateUrl: './category-dropdown.component.html',
  styleUrl: './category-dropdown.component.css'
})
export class CategoryDropdownComponent implements OnDestroy, OnInit {
  categories: PaginatedResult<Category> = initialPaginatedResult

  @Input() categoryControl!: FormControl

  subscriptions: Subscription[] = []

  @Input() remoteErrorMessage?: string

  constructor(private categoriesService: CategoriesService) {

  }

  ngOnInit(): void {
    this.getCategories()
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  loadCategories() {
    if (!this.categories.hasNextPage) {
      return;
    }

    this.categories.pageIndex++;

    this.getCategories()
  }

  getCategories(searchTerm?: string) {
    this.subscriptions.push(
      this.categoriesService
        .getCategories({
          pageIndex: this.categories.pageIndex,
          pageCount: 10,
          ascending: false,
          searchTerm: searchTerm
        })
        .subscribe((value) => {
          const oldResults = this.categories.results

          this.categories = value;

          this.categories.results = [... oldResults, ... value.results.filter(result => oldResults.filter(oldResult => oldResult.id === result.id).length === 0)]
        })
    )
  }

  search(term: string) {
    if (term.length === 0 || term.trim().length === 0) {
      this.getCategories()

      return
    }

    if (term.length < 3) {
      return
    }

    this.categories.pageIndex = 1

    this.getCategories(term)
  }
}
