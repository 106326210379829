<label class="form-label text-xl fw-bold required" *ngIf="withLabel">{{
  "orders.type" | translate
}}</label>
<select class="form-select" [formControl]="formControl">
  <option
    *ngFor="let type of orderTypes; let i = index"
    [ngValue]="type.value"
    [selected]="i === 0"
  >
    {{ "orders." + type.name | translate }}
  </option>
</select>

<div
  class="mt-2"
  *ngIf="(formControl.touched && !formControl.valid) || remoteErrorMessage"
>
  <app-error-alert
    [message]="
      errorMessageKey('orderType', formControl)
        | translate
        | default : remoteErrorMessage!
    "
  ></app-error-alert>
</div>
