import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { CountriesService } from '../../../services/countries.service';
import { Country } from '../../../models/models';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DefaultControlValueAccessor } from '../../../../utilities/control-value-accessor';

@Component({
  selector: 'app-countries-dropdown',
  templateUrl: './countries-dropdown.component.html',
  styleUrl: './countries-dropdown.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useClass: DefaultControlValueAccessor,
      multi: true
    }
  ]
})
export class CountriesDropdownComponent implements OnInit, OnDestroy, OnChanges {
  @Output() countrySelected = new EventEmitter<Country | null>()

  subscriptions: Subscription[] = []

  countries: Country[] = [] 

  form!: FormGroup<{
    country: FormControl<Country | null>
  }>

  @Input() initialCountryId?: number

  constructor(private countriesService: CountriesService, private formBuilder: FormBuilder) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.initialCountryId) {
      return
    }

    const country = this.countries.filter(value => value.id === this.initialCountryId)[0]

    if (!country) {
      return
    }

    this.countries = [country, ... this.countries.filter(value => value.id !== country.id)]

    this.countrySelected.emit(country)

    this.form.controls.country.setValue(country)
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      country: new FormControl<Country | null>(null)
    })

    this.subscriptions.push(this.form.controls.country.valueChanges.subscribe(value => {
      if (!value) {
        return
      }

      this.countrySelected.emit(value)
    }))

    this.subscriptions.push(this.countriesService.$countries.subscribe(value => {
      this.countries = value
    }))
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  compare(left: Country, right: Country) {
    return left.id === right.id
  }

  onClear() {
    this.countrySelected.emit(null)
  }
}
