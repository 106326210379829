import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Currency } from '../models/models';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor(private httpClient: HttpClient) {

  }

  getCurrencies() {
    return this.httpClient.get<Currency[]>(environment.url + 'currencies').pipe(map(response => {
      if ('results' in response) {
        return response.results as Currency[]
      }

      return response
    }))
  }
}
