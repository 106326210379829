<div [formGroup]="formGroup">
    <div formArrayName="deliveryZones">
        <!--begin::Input group=-->
        <div class="fv-row mb-4 d-flex flex-row justify-content-between align-content-stretch">
            <!--begin::Email-->
            <div class="col-md-10">
                <label class="form-label text-xl fw-bold">{{ 'orders.deliveryZone' | translate
                    }}</label>
                <input type="text" [formControl]="primaryZoneFormControl()" autocomplete="off"
                    class="form-control bg-transparent" />
                <input type="hidden" [formControl]="primaryIdFormControl()" autocomplete="off" 
                    class="form-control bg-transparent" />
                    <label class="form-label text-xl fw-bold mt-2">{{ 'shared.price' | translate
                    }}</label>
                <input type="number" [formControl]="primaryFeesFormControl()" autocomplete="off" [style.direction]="dir"
                    class="form-control bg-transparent mt-2" />
                <div class="mt-2" *ngIf="primaryDeliveryZoneRemoteErrorMessage">
                    <app-error-alert [message]="primaryDeliveryZoneRemoteErrorMessage"></app-error-alert>
                </div>
            </div>

            <div class="col-md-2 d-flex flex-row justify-content-end align-items-center">
                <!--begin::Indicator label-->
                <img src="assets/media/svg/plus.svg" class="w-50px h-25px hover:cursor-pointer align-self-center mt-8"
                    (click)="addZone()" />
                <!--end::Indicator label-->
            </div>
            <!--end::Email-->
        </div>

        <div class="fv-row mb-4 d-flex flex-row justify-content-between align-content-stretch"
            *ngFor="let zoneForm of deliveryZones.controls; let i = index" [formGroupName]="i">
            <!--begin::Email-->
            <div class="col-md-10">
                <label class="form-label text-xl fw-bold">{{ 'orders.deliveryZone' | translate
                    }}</label>
                <input type="text" [formControl]="zoneFormControl(i)" autocomplete="off" class="form-control bg-transparent"/>
                <label class="form-label text-xl fw-bold">{{ 'shared.price' | translate
                    }}</label>
                <input type="number" [formControl]="feesFormControl(i)" autocomplete="off" [style.direction]="dir"
                class="form-control bg-transparent mt-2" />
                <input type="hidden" autocomplete="off" [formControl]="idFormControl(i)" formControlName="id" class="form-control bg-transparent" />
                <div class="mt-2" *ngIf="deliveryZonesRemoteErrorMessages[i]">
                    <app-error-alert [message]="deliveryZonesRemoteErrorMessages[i]"></app-error-alert>
                </div>
            </div>

            <div class="col-md-2 d-flex flex-row justify-content-end align-items-center">
                <!--begin::Indicator label-->
                <img src="assets/media/svg/minus.svg" class="w-50px h-20px hover:cursor-pointer align-self-center"
                    (click)="removeZone(i)" />
                <!--end::Indicator label-->
            </div>
            <!--end::Email-->
        </div>
        <!--end::Input group=-->
    </div>
</div>