import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject, catchError, Subject, throwError } from 'rxjs';
import { NotificationType } from '../shared/models/enums';
import { Router } from '@angular/router';
import { NotificationsModel, PaginatedResult, NotificationRequest, initialPaginatedResult } from '../shared/models/models';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { getHttpParams } from '../utilities/params-utilities';
import { AuthenticationService } from '../authentication/services/authentication.service';
import { OrderDetailsComponent } from './../orders/components/order-details/order-details.component';

@Injectable({
  providedIn: 'root',
})

export class NotificationService {
  currentMessage = new BehaviorSubject<any>(null);
  private readonly DEVICE_TOKEN_STORAGE_KEY = 'DEVICE_TOKEN';
  AllNotification = new BehaviorSubject<PaginatedResult<NotificationsModel>>(initialPaginatedResult);

  deviceToken?: string;
  constructor(
    private AngularFireModule: AngularFireMessaging,
    private router: Router,
    private _HttpClient: HttpClient,
    private authenticationService: AuthenticationService
  ) { }

  async _getTokenDevice() {
    await Notification.requestPermission().then((permission) => {
      if (permission != 'granted') {
        return;
      }
    });
    this.AngularFireModule.requestToken.subscribe(
      (token) => {
        if (!token) {
          return;
        }
        localStorage.setItem(this.DEVICE_TOKEN_STORAGE_KEY, token);
        this.deviceToken = token;
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }
  _ListenMassege() {
    this.AngularFireModule.messages.subscribe((payload) => {
      if (!payload.notification?.title) {
        return;
      }

      this.showNotification(payload, {
        body: payload.notification.body,
        icon: '/assets/media/foodenza/logo-3.png',
        data: payload.data,
      });

      this.currentMessage.next(payload);
    });
  }

  private notificationRoutingHandler: {
    [key in NotificationType]: (payload: any, isMobile: boolean) => void
  } = {
      [NotificationType.OrderCreated]: (payload: any, isMobile: boolean) => {
        const url = `dashboard/orders/${payload.data.OrderNumber}`;

        if (isMobile) {
          window.location.href = url;
          return;
        }

        window.open(url, '_blank');
      },
      [NotificationType.OrderUpdated]: (payload: any, isMobile: boolean) => {
        const url = `customer/orderHistoryDetail/${payload.data.OrderId}`;

        if (isMobile) {
          window.location.href = url;
          return;
        }

        window.open(url, '_blank');
      },
      [NotificationType.StoreReviewCreated]: (payload: any, isMobile: boolean) => {
        const url = `dashboard/reviews`;

        if (isMobile) {
          window.location.href = url;
          return;
        }

        window.open(url, '_blank');
      },
      [NotificationType.ProductReviewCreated]: (payload: any, isMobile: boolean) => {
        const url = `dashboard/products/${payload.data.ProductId}?reviews=${true}`

        if (isMobile) {
          window.location.href = url

          return
        }

        window.open(url, '_blank')
      },
      [NotificationType.StoreSubscriptionExpiry]: (payload: any, isMobile: boolean) => {

      }
    }

  private showNotification(payload: any, options?: NotificationOptions) {
    if ('Notification' in window && Notification.permission === 'granted') {
      const notification = new Notification(
        payload.notification.title,
        options
      );

      notification.onclick = () => {
        const isOnMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

        const notificationType = payload.data.Type as NotificationType

        const handler = this.notificationRoutingHandler[notificationType]

        handler(payload, isOnMobile)
      };
    }
  }

  async getAllNotification() {
    if (!this.authenticationService.currentUser) {
      return;
    }
    const URL = environment.url + "accounts/notifications";
    await this._HttpClient.get<PaginatedResult<NotificationsModel>>(URL, {
      params: getHttpParams({
        IsRead: false
      })
    }).subscribe((value) => {
      this.AllNotification.next(value)
    });

  }

  private notificationReadHandler: {
    [key in NotificationType]: (payload: any) => void
  } = {
      [NotificationType.OrderCreated]: (payload: any) => {
        const orderNumber = payload.OrderNumber

        this.router.navigate([`dashboard/orders/${orderNumber}`])
      },
      [NotificationType.OrderUpdated]: (payload: any) => {
        const orderId = payload.OrderDetailsComponent

        this.router.navigate([`customer/orderHistoryDetail/${orderId}`])
      },
      [NotificationType.StoreReviewCreated]: (payload: any) => {
        this.router.navigate(['dashboard/reviews'])
      },
      [NotificationType.ProductReviewCreated]: (payload: any) => {
        const productId = payload.ProductId

        this.router.navigate([`dashboard/products/${productId}`], { queryParams: { reviews: true } })
      },
      [NotificationType.StoreSubscriptionExpiry]: (payload: any) => {
        this.router.navigate([`dashboard/subscriptions`])
      }
    }

  ReadNotification(notification: NotificationsModel) {
    if (!this.authenticationService.currentUser) {
      return;
    }
    const url = environment.url + `accounts/notifications/${notification.id}`
    this._HttpClient.patch(url, null).pipe(
      catchError(error => {
        console.error('An error occurred:', error);
        alert('Failed to update notification. Please try again later.');
        return throwError(() => error);
      })
    )
      .subscribe(
        _ => {
          const currentNotifications = this.AllNotification.getValue() || [];

          currentNotifications.count--;

          const updateNotifications = {
            ...currentNotifications,
            results: currentNotifications.results.filter((ele) => ele.id !== notification.id
            )
          }

          this.AllNotification.next(updateNotifications)

          const handler = this.notificationReadHandler[notification.type as NotificationType]

          handler(notification.payload)
        }
      );
  }
  async loadMoreNotification(NotificationRequest: NotificationRequest) {
    if (!this.authenticationService.currentUser) {
      return;
    }
    const url = environment.url + "accounts/notifications";
    const params = getHttpParams(NotificationRequest)
    await this._HttpClient.get<PaginatedResult<NotificationsModel>>(url, { params: params }).subscribe((value) => {
      const currentNotifications = this.AllNotification.getValue()?.results || [];
      const updatedNotifications = {
        ...value,
        results: [...currentNotifications, ...value.results]
      };
      this.AllNotification.next(updatedNotifications);
    });
  }
}
