export enum Language {
    AR = "Ar",
    EN = "En"
}

export enum Theme {
    LIGHT = 'light',
    DARK = 'dark'
}

export enum Response {
    BadRequest = 400,
    InternalServerError = 500,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    TooManyRequests = 429
}

export enum UnauthorizedReason {
    InvalidAccessToken = 0,
    AccountDeactivated = 1,
    NotHavePermissions = 2,
    StoreDeactivatedOrNotAccepted = 3
}

export enum ToastSeverity {
    Success = "success",
    Warning = "warning",
    Danger = "danger"
}

export enum BadgeSeverity {
    Danger = "danger",
    Success = "success",
    Primary = "primary"
}

export enum DayOfWeek {
    Sunday = "Sunday",
    Monday = "Monday",
    Tuesday = "Tuesday",
    Wednesday = "Wednesday",
    Thursday = "Thursday",
    Friday = "Friday",
    Saturday = "Saturday"
}
export enum NotificationType {
    OrderCreated = "OrderCreated",
    OrderUpdated = "OrderUpdated",
    StoreReviewCreated = "StoreReviewCreated",
    ProductReviewCreated = "ProductReviewCreated",
    StoreSubscriptionExpiry = "StoreSubscriptionExpiry"
}