<!--begin::Password-->
<label class="form-label text-xl fw-bold">{{ label }}</label>
<ng-select (search)="search($event.term)" (scrollToEnd)="paginateMainStores()" [formControl]="formControl" [items]="stores.results"  bindLabel="name"
    [minTermLength]="3" name="mainStoreId" notFoundText="{{ 'shared.emptyResults' | translate }}" [compareWith]="compare">
    <ng-template ng-label-tmp let-item="item">
        <div class="d-flex flex-row">
            <img [src]="item.logo" class="w-50px h-50px object-contain" alt="avatar">
            <span class="align-self-center">{{ item.name }}</span>
        </div>
      </ng-template>
    <ng-template ng-option-tmp let-item="item">
        <div class="d-flex flex-row">
            <img [src]="item.logo" class="w-50px h-50px object-contain" alt="avatar">
            <span class="align-self-center">{{ item.name }}</span>
        </div>
      </ng-template>
</ng-select>

<div class="mt-2" *ngIf="remoteErrorMessage || (formControl.touched && !formControl.valid)">
    <app-error-alert [message]="(('storeGeneralSearch' | errorMessagePipe: formControl) | translate) | default: remoteErrorMessage!"></app-error-alert>
</div>
<!--end::Password-->