import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { LoadingStateService } from '../services/loading-state.service';
import { finalize } from 'rxjs';

export const loadingInterceptor: HttpInterceptorFn = (req, next) => {
  const loadingService = inject(LoadingStateService)

  loadingService.show()

  return next(req).pipe(finalize(() => {
    loadingService.hide()
  }))
};
