<!--begin::Password-->
<label class="form-label text-xl fw-bold">{{ 'shared.country' | translate }}</label>
<ng-select [formControl]="form.controls.country" (clear)="onClear()" [items]="countries" bindLabel="name" name="country" notFoundText="{{ 'shared.emptyResults' | translate }}" [compareWith]="compare">
    <ng-template ng-label-tmp let-item="item">
        <div class="d-flex flex-row">
            <img [src]="item.flag" class="w-25px h-25px object-contain" alt="avatar" *ngIf="item.flag">
            <span class="align-self-center fw-bold mx-2">{{ item.name }}</span>
        </div>
      </ng-template>
    <ng-template ng-option-tmp let-item="item">
        <div class="d-flex flex-row">
            <img [src]="item.flag" class="w-25px h-25px object-contain" alt="avatar" *ngIf="item.flag">
            <span class="align-self-center fw-bold mx-2">{{ item.name }}</span>
        </div>
      </ng-template>
</ng-select>
<!--end::Password-->