import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DefaultControlValueAccessor } from '../../../../utilities/control-value-accessor';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { getErrorMessage } from '../../../../utilities/validators';
import { SettingsService } from '../../../services/settings.service';
import { Language } from '../../../models/enums';
import { Direction } from '@angular/cdk/bidi';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrl: './text.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useClass: DefaultControlValueAccessor,
      multi: true
    }
  ]
})
export class TextComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = []

  @Input() formControl!: FormControl

  @Input() remoteErrorMessage?: string

  @Input() label!: string

  @Input() required!: boolean

  @Input() fieldName!: string

  @Input() type: string = 'text'

  direction: Direction

  constructor(settingsService: SettingsService) {
    this.direction = settingsService.currentLanguage === Language.AR ? 'rtl' : 'ltr'
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  ngOnInit(): void {
    this.subscriptions.push(this.formControl.valueChanges.subscribe(value => {
      this.remoteErrorMessage = undefined
    }))
  }

  errorMessageKey(field: string, formControl: FormControl): string {
    return getErrorMessage(field, formControl)
  }

}
