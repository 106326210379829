import { HttpInterceptorFn } from '@angular/common/http';

export const timezoneInterceptor: HttpInterceptorFn = (req, next) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const newReq = req.clone({
    setHeaders: {
      'TimeZone': timezone
    }
  })

  return next(newReq)
};
