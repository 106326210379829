import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MessageResponse, PaginatedResult, ValidationFailureResponse } from '../../shared/models/models';
import { Category } from '../models/models';
import { getHttpParams } from '../../utilities/params-utilities';
import { catchError, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(private httpClient: HttpClient) { }

  getCategories(request: { pageIndex: number, pageCount: number, ascending: boolean, searchTerm?: string }) {
    const url = environment.url + "stores/categories"

    const params = getHttpParams(request)

    return this.httpClient.get<PaginatedResult<Category>>(url, { params: params })
  }

  getCategoryById(categoryId: number) {
    const url = environment.url + `stores/categories/${categoryId}`

    return this.httpClient.get<Category>(url)
  }

  deleteCategory(categoryId: number) {
    const url = environment.url + `stores/categories/${categoryId}`

    return this.httpClient.delete<MessageResponse>(url)
  }

  createCategory(request: { name: string, sortIndex: number }) {
    const url = environment.url + "stores/categories"

    return this.httpClient.post<MessageResponse>(url, request).pipe(catchError(err => {
      if ('validationMap' in err) {
        return of(err as ValidationFailureResponse)
      }

      return throwError(() => err)
    }))
  }

  updateCategory(request: { name: string, categoryId: number, sortIndex: number }) {
    const url = environment.url + `stores/categories/${request.categoryId}`

    return this.httpClient.put<MessageResponse>(url, request).pipe(catchError(err => {
      if ('validationMap' in err) {
        return of(err as ValidationFailureResponse)
      }

      return throwError(() => err)
    }))
  }
}
