import { PaginationRequest } from "../../shared/models/models";

export enum OrderStatus {
    pending = "Pending",
    cancelled = "Cancelled",
    ready = "Ready",
    completed = "Completed"
}

export enum OrderType {
    TakeAway = "TakeAway",
    DineIn = "DineIn",
    Delivery = "Delivery"
}

export interface Order {
    orderId: number;
    orderNumber: number;
    orderReferenceId: string;
    status: OrderStatus;
    type: OrderType;
    createdAt: Date;
    updatedAt: Date | null;
    amount: number;
    amountBeforeDiscount: number;
    comment: string;
    discountCode: DiscountCode | null;
    customer: Customer;
    storeId: number;
    storeSlug: string;
    storeName: string;
}

export interface Customer {
    id: string;
    name: string;
    email: string | null;
    phoneNumber: string | null;
}

export interface DiscountCode {
    id: number | null;
    code: string | null;
    percentage: number | null;
    maxAmount: number | null;
}

export interface OrderDetails {
    orderId: number;
    status: OrderStatus;
    type: OrderType;
    tableId: string | null;
    referenceId: string;
    orderNumber: number;
    deliveryAddress: string | null;
    deliveryFees: number | null;
    deliveryZone: string | null;
    createdAt: Date;
    updatedAt: Date;
    comment: string;
    amount: number;
    amountBeforeDiscount: number;
    items: OrderProductItem[];
    updates: OrderUpdate[];
    customer: Customer;
    discountCode: DiscountCode | null;
    storeId: number;
    storeName: string;
    storeSlug: string;
}

export interface OrderProductItem {
    id: number;
    title: string;
    image: string;
    price: number;
    categoryName: string;
    rate: number;
    currencyName: string;
    quantity: number;
    isDeleted: boolean;
    size: string | null;
    slug: string;
}

export interface OrderUpdate {
    id: number;
    status: string;
    comment: string;
    createdAt: Date;
}

export enum OrderQueryStatus {
    all = "all",
    pending = "pending",
    ready = "ready",
    cancelled = "cancelled",
    completed = "compeleted"
}

export interface GetOrdersRequest extends PaginationRequest {
    searchTerm: string | null,
    startDate: string | null,
    endDate: string | null,
    orderNumber: number | null,
    status: OrderQueryStatus
}


