export const environment = {
  url:'https://api.foodenza.com/api/',
  // url: 'https://localhost:7190/api/',
  firebaseConfig: {
    apiKey: "AIzaSyAnRUTuIFNPZxohFjxa7Km0vaUG8OvYZjM",
    authDomain: "foodenza-c9575.firebaseapp.com",
    projectId: "foodenza-c9575",
    storageBucket: "foodenza-c9575.appspot.com",
    messagingSenderId: "441658743364",
    appId: "1:441658743364:web:35730b6f232a690ad8fa11",
    measurementId: "G-QD8EDL00H5",
    vapidKey: 'BLFHmTo6H3xWE0LD7Qelxgv9NanhAjgMq-QU2USIqjn5t6xak6uNAuJJ_jYgdBM_k24HRiUGIl51Em8PTqRrAdI'
  }
};
