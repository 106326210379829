import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { initialPaginatedResult, PaginatedResult } from '../../models/models';
import { DefaultControlValueAccessor } from '../../../utilities/control-value-accessor';
import { PageEvent } from '@angular/material/paginator';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrl: './table-pagination.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useClass: DefaultControlValueAccessor,
      multi: true
    }
  ]
})
export class TablePaginationComponent implements OnInit, OnDestroy {
  @Input() pageCountControl!: FormControl<number>

  @Output() pageChanged = new EventEmitter()

  pages: { index: number }[] = []

  pageCountOptions = [10, 25, 50, 100]

  selectedPageCount = 25

  subscriptions: Subscription[] = []

  @Input() pagination: PaginatedResult<any> = initialPaginatedResult

  ngOnInit(): void {
    const pages = []

    for (let i = 0; i < this.pagination.pagesCount; i++) {
      pages.push({ index: i + 1 })
    }

    this.pages = pages

    this.subscriptions.push(this.pageCountControl.valueChanges.subscribe(value => {
      this.selectedPageCount = value
    }))
  }

  ngOnDestroy(): void {

  }

  paginate(index: number) {
    this.pagination.pageIndex = index

    this.pageChanged.emit()
  }

  onPageEvent(event: PageEvent) {
    this.paginate(event.pageIndex + 1)
  }
}
