import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { DefaultControlValueAccessor } from '../../../../utilities/control-value-accessor';
import { FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CountriesService } from '../../../services/countries.service';
import { Country } from '../../../models/models';
import { getErrorMessage } from '../../../../utilities/validators';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrl: './phone-number.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useClass: DefaultControlValueAccessor,
      multi: true
    }
  ]
})
export class PhoneNumberComponent implements OnInit, OnDestroy{
  subscriptions: Subscription[] = []

  countries: Country[] = []

  form!: FormGroup<{
    country: FormControl<Country | null>
  }>

  @Output() countrySelected = new EventEmitter<Country>()

  @Input() formControl!: FormControl

  @Input() remoteErrorMessage?: string

  @Input() label!: string

  @Input() required!: boolean

  @Input() fieldName!: string

  @Input() dialCode?: string | null

  constructor(private countriesService: CountriesService, private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      country: new FormControl<Country | null>(null)
    })
  }

  ngOnInit(): void {
    this.subscriptions.push(this.countriesService.$countries.subscribe(value => {
      this.countries = value

      this.form = this.formBuilder.group({
        country: new FormControl<Country | null>(this.countries[0])
      })

      this.subscriptions.push(this.form.controls.country.valueChanges.subscribe(value => {
        this.countrySelected.emit(value!)
      }))

      this.countrySelected.emit(this.countries[0])
    }))

    if (this.dialCode) {
      const country = this.countries.filter(country => country.dialCode === this.dialCode)[0]

      this.countries = [country, ... this.countries.filter(country => country.dialCode !== this.dialCode)]

      this.countrySelected.emit(country)

      return
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  compare(left: Country, right: Country) {
    return left.id === right.id
  }

  errorMessageKey(field: string, formControl: FormControl): string {
    return getErrorMessage(field, formControl)
  }
}
