import { ControlValueAccessor } from "@angular/forms";

export class DefaultControlValueAccessor implements ControlValueAccessor {
    writeValue(obj: any): void {

    }
    registerOnChange(fn: any): void {

    }
    registerOnTouched(fn: any): void {

    }
    setDisabledState?(isDisabled: boolean): void {

    }

}